import {HiOutlineMail} from 'react-icons/hi'
import {FaLinkedin} from 'react-icons/fa'
import {FaWhatsapp} from 'react-icons/fa'
import { AiFillGithub } from "react-icons/ai";

const data = [
    {id: 1, icon: <HiOutlineMail/>, link:'mailto:dorinadresha1@gmail.com'},
    {id: 2, icon: <FaLinkedin/>, link:'https://www.linkedin.com/in/dorinadresha/'},
    {id: 2, link: 'https://github.com/dorinadr', icon: <AiFillGithub/>},
    {id: 2, icon: <FaWhatsapp/>, link:'https://wa.me/+19293498956'}
    

]

export default data