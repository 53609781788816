import Image1 from '../../assets/project1.jpeg'
import Image2 from '../../assets/project2.png'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.png'
import Image5 from '../../assets/project5.jpeg'
import Image6 from '../../assets/project6.png'
import Image7 from '../../assets/project7.jpeg'
import Image8 from '../../assets/project8.png'
import Image9 from '../../assets/project9.png'



const data = [
    {
        id: 1, 
        category: 'projects',
        image: Image1,
        title: "KRYPTO CHEF - WEB3.0 App",
        desc: "I built a Web 3.0 blockchain app using React, Hello Vite, and Tailwind CSS. Developed a secure Ethereum smart contract for automated transactions. Managed crypto wallets with Meta Mask and enhanced scalability with Alchemy integration for faster transactions.",
        demo: "https://kryptochef.info/",
        github: 'https://github.com/dorinadr/Krypt.git' 
    },
 
       
    {
        id: 2,
        category: 'internships',
        image: Image2,
        title: "Protector",
        desc: "I developed a web and mobile application for reporting gas leaks, utilizing Node.js and MongoDB technologies. The application included a map-based interface powered by the Google Maps API, allowing users to pinpoint and report gas leaks easily. ",
        demo: "",
        github: 'https://github.com/dorinadr/Protector  ' 
    },

    {
        id: 3,
        category: 'projects',
        image: Image3,
        title: "Carmania",
        desc: "Carmania is an ecommerce website where users can both rent and buy cars. The website features a shopping cart system implemented with JavaScript, HTML, CSS, PHP, and MySQL. This technology stack enables seamless navigation, secure transactions, and a user-friendly experience for both renting and purchasing vehicles on the platform.",
        demo: "",
        github: 'https://github.com/dorinadr/Carmania  ' 
    },

    {
        id: 4,
        category: 'internships',
        image: Image4,
        title: " BNY Mellon ",
        desc: "I played a key role in enhancing financial advisory services at BNY MELLON. I implemented real-time updates for new account trades, providing advisors with the latest information for informed decisions. I seamlessly integrated front-end/UI components with APIs, optimized data retrieval, and enabled swift actions on critical insights. I built, tested, and deployed software solutions supporting Global Wealth Management and Asset Management with assets exceeding $3 trillion.",
      
    },
    {
        id: 5,
        category:'activity',
        image: Image5,
        title: "BASTA Google Software Engineering Program G-SWEP	",
        desc: "In this role, I proactively engaged with a mentor from the G-SWEP program on a weekly basis to gain valuable coding practice and receive insightful guidance for building a successful career in the technology industry. Through this mentorship, I had the opportunity to explore a clear pathway towards securing internships and high-paying positions that are in-demand within the tech field. This experience not only enhanced my technical skills but also provided me with a strategic direction for achieving my professional goals and making meaningful contributions to the tech industry.",
        github: 'https://github.com/dorinadr/Carmania  ' 
    },
    {
        id: 6,
        category: 'activity',
        image: Image6,
        title: "Ideathon",
        desc: "During the IDEATHON, I played a key role in developing and leading a team for an innovative mobile app. I took charge of team leadership, fostered collaboration, and ensured timely delivery under challenging deadlines. This experience not only contributed to the app's development but also improved my leadership and cross-functional teamwork skills essential in the fast-paced tech and innovation sector.",
        demo: "",
        github: 'https://github.com/dorinadr/Carmania  ' 
    },
    {
        id: 7,
        category: 'activity',
        image: Image7,
        title: "S.T.A.R.T.U.P, BNY Mellon",
        desc: "I participated in a weekly mentorship program focused on Computer Information Systems (CIS). This valuable experience provided tailored advice on career goals, job preparation, industry trends, and skills development. The mentorship significantly contributed to my personal and professional growth, enhancing my understanding of the tech industry and preparing me for future opportunities.",
       
        
    },
    {
        id: 8,
        category: 'certificate',
        image: Image8,
        title: "React Summit",
        desc: "I earned the React Summit certificate by actively participating in the event, attending sessions, workshops, and engaging in various activities. This certificate represents my commitment to staying informed about the latest advancements in React and reflects my dedication to continuous learning and involvement in the React community.",
   
    
},
{
        id: 9,
        category: 'certificate',
        image: Image9,
        title: "Code Path *Org ",
        desc: "Preparation course to boost my skills for technical interviews. The course covered coding challenges, problem-solving strategies, and technical communication. With hands-on practice and guidance from experienced instructors, I gained the knowledge and confidence needed to excel in technical interviews.",
   
    
},



  
]

export default data