import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'

const data = [


    {
    id: 2, 
    icon: <RiReactjsLine/>, 
    title: 'Programming Languages', 
    desc: "C++, Java, Ruby, C#, Python, JavaScript, HTML/CSS, PHP "
    },

    {
    id: 3, 
    icon: <SiAdobexd/>, 
    title: 'Frameworks', 
    desc: "React, Vue, Next, Express, Spring Boot, Angular"
    },

    {
    id: 4, 
    icon: <AiFillAppstore/>, 
    title: 'Technologies', 
    desc: 'NodeJS/NestJS, RESTful APIs, Git/GitHub, Subversion, CI/CD'
    },
    {
        id: 1, icon: <FaServer/>,
        title: 'Database', 
        desc: "MySQL, MongoDB"
   }

]

export default data