import { FaLinkedin } from "react-icons/fa";
import { AiFillGithub } from "react-icons/ai";

export const links = [
    {id: 1, link: '#', title: 'Home'},
    {id: 2, link: '#about', title: 'About'},
    {id: 3, link: '#services', title: 'Skills'},
    {id: 4, link: '#portfolio', title: 'Portfolio'},
    {id: 5, link: '#contact', title: 'Contact'}
]

export const socials = [
    {id: 1, link: 'https://www.linkedin.com/in/dorinadresha/', icon: <FaLinkedin/>},
    {id: 2, link: 'https://github.com/dorinadr', icon: <AiFillGithub/>} 
]

