import { useThemeContext } from "/Users/dorinadresha/Desktop/react-portfolio/src/context/theme-context.js"



const BackgroundColor = ({className}) => {
  const {themeHandler} = useThemeContext() 
  return (
    <button className={className} onClick={() => themeHandler(className)}>

    </button>
  )
}

export default BackgroundColor