import AboutImage from '../../assets/logo.jpg'
import Resume from '../../assets/resume.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'



const About = () => {
    return (
       <section id="about">
        <div className="container about_container">
            <div className="about_left">
                <div className="about_portrait">
                    <img className="image" src={AboutImage} atl="About Image" />
                </div>
            </div>
            <div className="about_right">
                <h2>About Me</h2>
                <div className="about_cards">
                {
                    data.map(item => (
                        <Card key ={item.id} className="about_card">
                            <span className='about_card-icon'>{item.icon}</span>
                            <h5>{item.title}</h5>
                            <small>{item.desc}</small>
                        </Card>
                    ))
                }
                </div>
              
                <p>
                I am Dorina Dresha, a passionate and dedicated Computer Science professional based in Brooklyn, NY. I hold a Bachelor of Science in Computer Science from Brooklyn College, CUNY, with a GPA of 3.3. My academic journey has equipped me with a solid foundation in key areas such as Discrete Structures and Algorithms, 
                Java OOP, Web Development, Database Systems, Operating Systems, and Computer Networks. Additionally, I earned an Associate of Science in Computer Science from Borough of Manhattan Community College (BMCC), CUNY, further enriching my knowledge and skills in the field. 
                I am enthusiastic about leveraging my education and experience to contribute to innovative projects and solutions. 
                </p>
              
                <a href ={Resume}download className='btn primary'>
                    Download Resume<HiDownload/></a>
            </div>
        </div>
       </section>
        
    )
}

export default About