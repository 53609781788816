import './services.css'
import data from './data'

import Card from '../../components/Card';



const Services = () => {
  return (
    <section id="services">
      <h2>My Skills</h2>
      <p></p>

      <div className="container services_container">
        {
          data.map(item => (
            <Card key={item.id} className="service light">
              <div className="service_icon">{item.icon}</div>
              <div className="service_details">
                <h4>{item.title}</h4>
                <p>{item.desc}</p>
              </div>
            </Card>
          ))
        }
      </div>
    </section>
  )
}

export default Services