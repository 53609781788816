import Logo from '../../assets/logo.jpg'
import './navbar.css'
import data from './data'
import {IoIosColorPalette} from 'react-icons/io'
import {useModalContext} from '../../context/modal-context'


const Navbar = () => {
  const {showModalHandler} = useModalContext();
  return (
    <nav> 
      <div className="container nav_container">
        <a href="index.html" className='nav_logo'>
        <img className="img2" src={Logo} alt="Logo" />
        </a>
         <ul className='nav_menu'>
          {
            data.map(item => <li key ={item.id}><a href={item.link}>{item.title}
            </a></li>)
          }
         </ul>
        <button id='theme_icon' onClick={showModalHandler}><IoIosColorPalette/></button>
      </div>
    </nav>
  )
}

export default Navbar